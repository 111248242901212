import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Button from "../../atoms/button/Button";
import SectionTitle from "../../molecules/section-title/SectionTitle";
import { Events, track } from "../../utils/analytics";
import useTrackPageViewed from "../../utils/hooks/useTrackPageViewed";
import { Result } from "../result/domain/result";
import { useResult } from "../result/use-cases/result-use-cases";
import * as styles from "./Start.module.scss";

interface Image {
  default: string;
}

const startSVG: Image =
  require("../../images/pages/results/small-print/magnifierAndEye.svg") as Image;

const startSVGSrc: string = startSVG.default;

const Start = (): JSX.Element => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const nextUrl = `/results/${result?.uuid}/small_print/waiting_period/`;

  useTrackPageViewed(Events.SMALL_PRINT_START_VIEWED_BROWSER);

  const handleClick = () => {
    track(Events.CLICKED_CTA, { eventSender: "Small Print index page CTA" });

    void navigate(nextUrl);
  };

  return (
    <section className={styles.start}>
      <img
        className={styles.image}
        src={startSVGSrc}
        alt=""
        width="88"
        height="88"
        loading={"lazy"}
      />
      <SectionTitle
        title={t("small_print.start.title")}
        subtitle={t("small_print.start.subtitle")}
        adoptionClassName={styles.sectionTitle}
      />
      <Button type={"button"} onClick={handleClick} adoptionClassName={styles.cta}>
        {t("common.cta.start")}
      </Button>
    </section>
  );
};

export default Start;
