import { graphql } from "gatsby";

import PageStepAnimation from "../../../../atoms/page-step-animation/PageStepAnimation";
import withFunnelWrapper from "../../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import { Result } from "../../../../organisms/result/domain/result";
import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import Start from "../../../../organisms/small-print/Start";
import { PageId } from "../../../../settings/pages";

const Page = () => {
  const result = useResult() as Result;

  return (
    <>
      {result && (
        <PageStepAnimation>
          <Start />
        </PageStepAnimation>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "", PageId.smallPrint);

export const query = graphql`
  query SmallPrintIndexRequestQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
